var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"darken-3",attrs:{"app":"","id":"navBar","dark":"","flat":""}},[(_vm.mobile && _vm.$store.state.admin != '' && _vm.$route.name != 'login')?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('v-toolbar-title',{staticClass:"mt-2"},[_vm._v("Admin Portal")])],1),_c('v-spacer'),(_vm.$store.state.admin != '' && _vm.$route.name != 'login')?_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.$store.dispatch('loginAdmin', '');
        _vm.$router.push({ name: 'login' });}}},[_c('span',{staticClass:"text-capitalize mr-2"},[_vm._v("logout")])]):_vm._e()],1),(_vm.mobile && _vm.$store.state.admin != '' && _vm.$route.name != 'login')?_c('v-navigation-drawer',{attrs:{"width":"auto","app":"","id":"navDrawer"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"pl-8 mt-10 mr-5"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs3":""}},[_c('span',[_c('v-avatar',{attrs:{"size":"28","color":"grey"}})],1)]),_c('v-flex',{staticClass:"pt-1 pl-1",attrs:{"xs5":""}},[_c('span',{staticClass:"white--text",attrs:{"dark":""}},[_vm._v("Apice Virtual")])])],1)],1),_c('br'),_c('v-divider',{staticClass:"grey"}),_c('v-list',{staticClass:"pt-5",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'home',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("home")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Dashboard")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'cashier',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("monetization_on")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Client")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'shift',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("repeat_on ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Shift")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'gamestat',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("laptop ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Game Stat")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'gameAnalysis',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("bar_chart ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Game Analysis")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'packages',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("badge ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Packages and Loans")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'reportedTickets',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("pan_tool_alt ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Reported Tickets")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'search',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("search ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Search Result")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'setting',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("settings ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Setting")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'help',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("help ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Help")])],1)],1)],1)],1)],1):_vm._e(),(!_vm.mobile && _vm.$store.state.admin != '' && _vm.$route.name != 'login')?_c('v-navigation-drawer',{attrs:{"width":"auto","permanent":"","app":"","id":"navDrawer"},model:{value:(_vm.drawer1),callback:function ($$v) {_vm.drawer1=$$v},expression:"drawer1"}},[_c('div',{staticClass:"pl-8 mt-10 mr-5"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs3":""}},[_c('span',[_c('v-avatar',{attrs:{"size":"28","color":"grey"}})],1)]),_c('v-flex',{staticClass:"pt-1 pl-1",attrs:{"xs5":""}},[_c('span',{staticClass:"white--text",attrs:{"dark":""}},[_vm._v("Apice Virtual")])])],1)],1),_c('br'),_c('v-divider',{staticClass:"grey"}),_c('v-list',{staticClass:"pt-5",attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'home',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("home")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Dashboard")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'cashier',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("monetization_on")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Client")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'shift',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("repeat_on ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Shift")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'gamestat',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("laptop ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Game Stat")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'gameAnalysis',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("bar_chart ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Game Analysis")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'packages',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("badge ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Packages and Loans")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'reportedTickets',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("pan_tool_alt ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Reported Tickets")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'search',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("search ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Search Result")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'setting',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("settings ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Setting")])],1)],1),_c('v-list-item',{staticClass:"white--text mb-4",on:{"click":function($event){_vm.$router
              .push({
                name: 'help',
              })
              .catch(function (err) {})}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2 grey--text text--lighten-3"},[_vm._v("help ")]),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("Help")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }